
@import url('https://fonts.googleapis.com/css2?family=Share+Tech&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background: rgb(20, 19, 19);
  font-family: 'Share Tech', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: 0px;
}
::-webkit-scrollbar {
  width: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar  */
.navbar {
  background: #00000087;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 40px;
  flex-flow: row wrap;
}

.navbar__logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__logo {
  width: 4rem;
  max-width: 90vw;
}

/* Wallet Connect  */
.utility__connect-disconnect-btn {
  background: #000000 !important;
  line-height: normal !important;
  height: auto !important;
  padding: 0.5rem 1rem !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  border: 1px solid rgb(0, 255, 255) !important;
  border-radius: 0px !important;
  color: rgb(0, 255, 255) !important;
  font-family: 'Share Tech', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    overflow: hidden;
}

.utility__connect-disconnect-btn:hover {
  background: #0a1e22 !important;
}

.utility__connect-disconnect-btn>.wallet-adapter-button-start-icon {
  height: 1.2rem;
  width: 1.2rem;
}

.wallet-adapter-button {
  font-family: 'Share Tech' !important;
}

.wallet-adapter-modal-wrapper {
  background: #091e22 !important;
  border-radius: 0px !important;
  font-family: 'Share Tech' !important;
  border: 2px solid cyan !important;
}
