.trait-shop__listed-traits-categories__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0px;
    flex-flow: row wrap;
}

.trait-shop__listed-trait-category__container {
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    width: fit-content;
    background: #0d0927;
    border: 2px solid #4e79ff;
}

.trait-shop__listed-trait-category__title {
    text-transform: uppercase;
    color: white;
    font-size: 1.5rem;
    border-bottom: 2px solid white;
    width: 100%;
    padding-bottom: 0.5rem;
}

.trait-shop__listed-traits__container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    flex-flow: row wrap;
}

.trait-shop__listed-trait__container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 0.5rem;
    border: 1px solid rgb(0, 255, 255);
    background: #0a1e22;
    width: 150px;
    max-width: calc(50vw - 2.2rem);
    height: fit-content;
}

.trait-shop__listed-trait__img {
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    max-width: 80vw;
}

.trait-shop__listed-trait__name {
    color: white;
    font-size: 1.3rem;
    border-radius: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.trait-shop__listed-trait__info-container {
    color: white;
    padding: 0.5rem 0px 0.1rem 0px;
    width: 100%;
    overflow: hidden;
    background: #153b3d;
}



.trait-shop__hover-animated-btn {
    position: relative;
    background: #444;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    --clr: #0FF0FC;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
    padding: 0.7rem 0.7rem;
    transition: 0.2s;
}

.trait-shop__hover-animated-btn:not(:disabled):hover {
    background: var(--clr);
    color: var(--clr);
}


.trait-shop__hover-animated-btn::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: #272822;
}

.trait-shop__hover-animated-btn span {
    position: relative;
    z-index: 1;
}

.trait-shop__hover-animated-btn i {
    position: absolute;
    inset: 0;
    display: block;
}

.trait-shop__hover-animated-btn i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
}

.trait-shop__hover-animated-btn:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
}

.trait-shop__hover-animated-btn i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
}

.trait-shop__hover-animated-btn:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
}

@keyframes move {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes box {
    0% {
        box-shadow: #27272c;
    }

    50% {
        box-shadow: 0 0 25px var(--clr);
    }

    100% {
        box-shadow: #27272c;
    }
}

.trait-shop__panel-button-container {
    display: flex;
    flex-flow: row wrap;
    gap: 0.2rem;
    justify-content: center;
    width: fit-content;
    margin: auto;
}

.trait-shop__panel-button {
    background: #645fff !important;
    color: white !important;
    border: 1px solid black !important;
    border-radius: 10px !important;
    padding: 0.2rem 1.3rem !important;
    height: auto !important;
    min-height: auto !important;
    font-size: 1rem !important;
}

.trait-shop__panel-button-active {
    background: #3d37ff !important;
}

.trait-shop__listed-trait__buy-row:hover {
    background: #351a69;
    cursor: pointer;
}

.trait-shop__my-trait__number-of-items {
    position: absolute;
    bottom: 0.4rem;
    right: 0.2rem;
    padding: 0.5rem;
    color: white;
    font-weight: 800;
    font-size: 1.3rem;
    border: 2px solid cyan;
    border-radius: 100px;
    width: 10px;
    aspect-ratio: 1 / 1;
    background: cyan;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.trait-shop__listed-trait-apply__form {
    display: flex;
    justify-content: center;
    padding: 1rem 0px;
    flex-flow: row wrap;
}

.trait-shop__listed-trait-apply__form img {
    width: 100px;
    border: 2px solid #3c3d3d;
    max-width: 80%;
}
