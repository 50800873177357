.css-uqwyvf-MuiButtonBase-root-MuiTab-root {
    background: #645fff !important;
    color: white !important;
    border: 1px solid black !important;
    margin: 0px 0.2rem !important;
    border-radius: 10px !important;
    padding: 0.5rem 1.3rem !important;
    height: auto !important;
    min-height: auto !important;
}

.css-uqwyvf-MuiButtonBase-root-MuiTab-root.Mui-selected {
    background: #3d37ff !important;
}

.css-1aquho2-MuiTabs-indicator {
    display: none !important;
}

.css-1ujnqem-MuiTabs-root {
    min-height: fit-content !important;
}

.css-13xfq8m-MuiTabPanel-root {
    padding: 0px !important;
}